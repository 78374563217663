import React from "react"
import { graphql, Link } from "gatsby"

import BackgroundImage from "gatsby-background-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  PageWrapper,
  TextWrapper,
  HeadingWrapper,
  FlexWrapper,
  FlagWrapper,
} from "../../components/contentwrappers"
import ActionButton from "../../components/actionbutton"

import Img from "gatsby-image"

export default function Blog({ data }) {
  const { posts } = data.blog

  return (
    <div>
      <BackgroundImage
        fluid={data.royalCourtsImage.childImageSharp.fluid}
        alt="The Royal Courts of Justice"
        style={{
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "-9",
        }}
      ></BackgroundImage>

      <Layout>
        <SEO title="Finding True Wealth" />
        <PageWrapper>
          <HeadingWrapper>
            <h1>
              Finding <strong>True Wealth</strong>
            </h1>
          </HeadingWrapper>

          <TextWrapper>
            <p>
              Welcome to our blog, Finding True Wealth. Being "wealthy" means
              different things to different people. For some it can refer to
              their money and assets but for others it may mean financial
              freedom and having the means to fulfil life goals etc. Here we'll
              include articles to help you on your way to find your "True
              Wealth".
            </p>

            <p>
              As well as these articles, we produce guides from time-to-time.{" "}
              <Link to="/guides">Click here</Link> to browse our previous
              guides.
            </p>

            <p>
              Articles that we publish to our professional connections can found
              found <Link to="/enformer">here</Link>.
            </p>
          </TextWrapper>

          <FlexWrapper blogs>
            {posts.map(post => (
              <FlagWrapper key={post.id}>
                <h3>{post.frontmatter.title}</h3>

                <small>{post.frontmatter.date}</small>
                <Img
                  fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
                />
                <p style={{ margin: "0" }}>{post.frontmatter.description}</p>

                <ActionButton toRef={post.fields.slug}>
                  Read <strong>More</strong>
                </ActionButton>
                <p></p>
              </FlagWrapper>
            ))}
          </FlexWrapper>
        </PageWrapper>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM YYYY")
          title
          description
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        excerpt
        id
      }
    }

    royalCourtsImage: file(
      relativePath: { eq: "royal-courts-of-justice.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 5031, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
